<template>
    <div>
        <mdb-card class="mb-2">
            <mdb-card-header>搜尋</mdb-card-header>
            <mdb-card-body>
                <mdb-row>
                    <mdb-col md="3">
                        <mdb-select label="目前分店"
                                    placeholder="選擇目前分店"
                                    @getValue="val=>{search.id_store=val; }"
                                    v-model="storeList"></mdb-select>
                    </mdb-col>
                    <mdb-col md="3">
                        <mdb-select label="狀態"
                                    placeholder="選擇狀態"
                                    @getValue="val=>{search.order_status=val; }"
                                    v-model="order_statusList"></mdb-select>
                    </mdb-col>
                    <mdb-col md="3">
                        <datepicker label="日期(起)"
                                    v-model="search.day_start"
                                    :max="new Date()" />
                    </mdb-col>
                    <mdb-col md="3">
                        <datepicker label="日期(迄)"
                                    v-model="search.day_end"
                                    :min="search.day_start"
                                    :max="new Date()" />
                    </mdb-col>
                    <mdb-col md="3">
                        <mdb-btn color="default"
                                 size="sm"
                                 class="mt-3"
                                 @click="data.nowPage=1;searchdata()">
                            <i class="fa fa-search"></i>
                            搜&nbsp;&nbsp;尋
                        </mdb-btn>
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <mdb-card>
            <mdb-card-body>
                <div class="rwd-table-dispersion">
                    <table class="table table-striped">
                        <!--Table head-->
                        <thead>
                            <tr>
                                <th style="width:3rem">#</th>
                                <th style="width:7rem">時間</th>
                                <th class="text-nowrap"
                                    style="width:10rem">客戶姓名/電話</th>
                                <th class="text-nowrap"
                                    style="width:10rem">機種</th>
                                <th>銷售品項</th>
                                <th class="text-nowrap"
                                    style="width:7rem">總金額</th>
                                <th class="text-nowrap"
                                    style="width:4rem">狀態</th>
                                <th style="width:10rem"></th>
                            </tr>
                        </thead>
                        <!--Table head-->
                        <!--Table body-->
                        <tbody>
                            <tr v-for="(o,ok) in data.ordersList"
                                :key="`order_${ok}`">
                                <td data-title="#"
                                    scope="row">{{(ok+1)+(data.nowPage-1)*10}}</td>
                                <td data-title="時間">{{o.day}}</td>
                                <td data-title="客戶姓名/電話"
                                    style="min-height:3.2em">{{o.name}}<br>{{o.cel}} </td>
                                <td data-title="機種"
                                    style="min-height:1em">
                                    <p v-for="cp in o.customer_device"
                                       :key="`cp_${cp.id}`">
                                        {{cp.text}}
                                    </p>
                                </td>
                                <td data-title="銷售品項">
                                    <div v-if="o.first_item!=''">
                                        <span :style="o.first_item.remove=='1' ? 'text-decoration: line-through' : ''">{{o.first_item.product_name}}</span><br>
                                    </div>
                                    <small style="float:right">共{{o.item_number}}項</small>
                                    <div style="clear:both"></div>
                                </td>
                                <td data-title="總金額">{{$numeral(o.total).format('0,0')}}</td>
                                <td data-title="狀態">
                                    <mdb-badge :color="o.status=='0'?'success':'warning'">
                                        {{order_statusKeyList[o.status]}}
                                    </mdb-badge>
                                </td>
                                <td class="text-right text-md-left">
                                    <router-link class="btn btn-info btn-sm   text-nowrap"
                                                 :to="`/orders/view/${o.id}`"><i class="fa fa-eye"></i>
                                        &nbsp;檢視</router-link>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="8"
                                    v-show="data.ordersList.length==0">無資料</td>
                            </tr>
                        </tbody>
                        <!--Table body-->
                    </table>
                </div>
                <page :nowPage="data.nowPage"
                      :totalPage="data.totalPage"
                      @switchpage="pg=>{search.page=pg;getData();}"
                      v-show="data.ordersList.length>0" />
            </mdb-card-body>
        </mdb-card>
    </div>
</template>
<script>
import {
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbSelect,
  mdbBadge,
} from "mdbvue";
import datepicker from "../../components/datepicker";

import page from "../../components/page";
export default {
  props: { query: { default: {} } },
  components: {
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbSelect,
    datepicker,
    page,
    mdbBadge,
  },
  data() {
    return {
      search: {
        id_store: "",
        order_status: "",
        day_start: "",
        day_end: "",
        page: 1,
      },
      search_change: false,
      storeList: [],
      order_statusList: [],
      order_statusKeyList: {},
      data: {
        ordersList: [],
        nowPage: 1,
        totalPage: 0,
      },
    };
  },
  mounted() {
    let vue = this;
    // 把篩選的資料放到變數裡面
    for (let i in vue.search) {
      switch (i) {
        case "id_store":
          vue.search[i] = vue.query[i]
            ? vue.query[i]
            : vue.$store.state.id_store;
          break;
        case "day_start":
        case "day_end":
          vue.search[i] = vue.query[i]
            ? vue.query[i]
            : vue.$moment().format("YYYY-MM-DD");
          break;
        default:
          vue.search[i] = vue.query[i] ? vue.query[i] : "";
          break;
      }
    }
    vue.search.page = vue.search.page != "" ? vue.search.page : 1;
    //
    let query = [
      {
        name: "store",
        data: {
          active: {
            type: 0,
            value: 1,
          },
        },
        sort: {
          name: 0,
        },
      },
      {
        name: "sys_parameter",
        data: {
          disable: {
            type: 0,
            value: "0",
          },
          type: {
            type: 0,
            value: "order_status",
          },
        },
      },
    ];
    vue.$store
      .dispatch("get_form", {
        payload: {
          url: `data/get_data/?query=${JSON.stringify(query)}`,
        },
      })
      .then((res) => {
        vue.order_statusList = [
          {
            text: "全部",
            value: "",
            selected: vue.search.order_status == "",
          },
        ];
        res.data.sys_parameterList.forEach((item) => {
          vue.order_statusList.push({
            text: item.value,
            value: item.key,
            selected: vue.search.order_status == item.key,
          });
          vue.order_statusKeyList[item.key] = item.value;
        });
        res.data.storeList.unshift({ name: "全部", id: "" });
        vue.storeList = res.data.storeList.map((item) => {
          item.value = item.id;
          item.text = item.name;
          if (vue.search.id_store == item.value) {
            item.selected = true;
          }
          return item;
        });
      });
    vue.getData();
  },
  watch: {
    search: {
      handler() {
        this.search_change = true;
      },
      deep: true,
    },
  },
  methods: {
    // 搜尋資料
    searchdata() {
      let vue = this;
      if (vue.search_change) {
        let url = vue.$route.path + "?";
        for (let k in vue.search) {
          url += `${k}=${vue.search[k]}&`;
        }
        vue.$router.push(url);
      }
      vue.search_change = false;
      vue.getData();
    },
    getData() {
      let vue = this,
        search_str = "";
      for (let k in vue.search) {
        search_str += `${k}=${vue.search[k]}&`;
      }
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `orders/orders_list/?${search_str}`,
          },
        })
        .then((res) => {
          res.data.ordersList.map((item) => {
            item.customer_device = JSON.parse(item.customer_device);
            return item;
          });
          Object.assign(vue.data, res.data);
        });
    },
  },
};
</script>